<template>
    <div>
    
        <b-row lass="vh-100 text-center" align-v="center">
            <b-col offset-sm-2 sm="12" md="10" offset-lg="2" lg="6" align-self="center" class="mx-auto">
                <b-card class="pricing-basic-value font-weight-bolder text-primary" title="Fund Your Wallet 🚀">
                   <add-bvn />
                </b-card>
               
            </b-col>
                 
            
            
        </b-row>
    
    
       
    </div>
</template>

<script>
import { BCard, BCardText, BButton, BFormInput, BFormGroup,  BContainer, BRow, BCol } from 'bootstrap-vue'

import AddBvn from '../components/AddBvn.vue'
import QuickFund from '../components/QuickFund.vue'

export default {
    components: {
        BCard,
        BContainer,
        BFormInput,
        BFormGroup,
        BButton,
        AddBvn,
        QuickFund,
 
        BCardText,
        BRow,
        BCol,
      
    },
}
</script>

